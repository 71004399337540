<template>
  <div class="xy">
    <div v-if="show == 0">
      <h3>儿童个人信息保护政策</h3>
      <p class="jz">智慧视光应用程序儿童个人信息保护政策</p>
      <p></p>
      <p>请用户在继续使用智慧视光应用程序前务必认真仔细阅读并确认充分理解《智慧视光应用程序儿童个人信息保护政策》，非经未满14周岁未成年人（"儿童"）的监护人同</p><p>意，用户不得通过智慧视光应用程序上传或提供儿童个人信息。用户作为儿童监护人（"监护人"）通过智慧视光应用程序为儿童预约就诊或关联儿童就诊信息的，视为用</p>户<p>同意向智慧视光应用程序提供儿童相关信息。</p>
      <p>一、总则 </p>
      <p>（一）定义</p>
      <p>1.【智慧视光应用程序】</p>
      <p>本条款中所称"智慧视光应用程序"指智慧视光医疗官网、微官网、微信小程序的单称或合称。所有用户在使用智慧视光应用程序前，应认真阅读本条款 。</p>
      <p>2.【智慧视光】</p>
      <p>本条款项下所称"智慧视光"指深圳市博士视觉健康科技有限公司及/或智慧视光应用程序中所列示的相关医院或诊所。 </p>
      <p>3.【儿童信息】</p>
      <p>指所有与儿童相关的信息，包括儿童的个人信息。</p>
      <p>4.【儿童个人信息】</p>
      <p>指以电子或者其他方式记录的能够单独或者与其他信息结合识别儿童个人身份的各种信息，包括但不限于儿童姓名、头像、护照、身份证号、职业、职务、手机电话、银</p>行<p>账号、保险卡号、第三方社交账号、第三方支付账号、电子邮件、个人身高、体重、性别、年龄、血型、个人及家族病史、病情描述、就医需求、保险信息等个人生理</p>体征<p>及医疗健康等相关信息。</p>
      <p> 5.【智慧视光修改权】</p>
      <p>智慧视光有权修改本条款并通过智慧视光应用程序公告通知监护人。如监护人在本条款更新后拟继续使用智慧视光应用程序，应了解、接受并遵守更新后的儿童个人信息</p>保<p>护条款。</p>
      <p>（二）适用范围</p>
      <p>本条款适用于智慧视光通过运营的智慧视光应用程序为用户提供的服务。需要特别说明的是，本条款不适用于智慧视光应用程序平台上显示但由其他接入平台第三方向用</p>户<p>提供的服务。</p>
      <p>二、儿童个人信息保护条款 </p>
      <p>（一）【个人信息搜集】</p>
      <p>1.【信息搜集原则】</p>
      <p>智慧视光应用程序将严格遵循合法、正当、必要的原则收集及使用儿童个人信息。 </p>
      <p>2.【信息搜集目的】</p>
      <p>智慧视光应用程序所搜集的儿童个人信息将用于按照监护人预约或咨询内容为儿童提供相应服务及用于持续改进智慧视光服务水平、智慧视光应用程序功能及用户体</p>验。
      <p>3.【信息搜集方式】</p>
      <p>智慧视光应用程序将通过自动收集方式、监护人注册、预约及咨询主动提供搜集儿童信息。 </p>
      <p>当监护人使用智慧视光应用程序时，对于监护人为了儿童使用智慧视光应用程序提供的相关功能和/或服务而主动提供的信息，智慧视光会予以收集。具体如下：</p>
      <p>当监护人使用智慧视光应用程序为儿童进行线上预约就诊、咨询等时，为了监护人能够顺利地为儿童完成预约就诊，智慧视光会收集用户主动提供的儿童姓名、头像、护</p><p>照、身份证号、职业、职务、手机电话、银行账号、保险卡号、第三方社交账号、第三方支付账号、电子邮件、个人身高、体重、性别、年龄、血型、个人及家族病史、</p>病<p>情描述、就医需求、保险信息等个人生理体征及医疗健康等相关信息；当监护人使用智慧视光应用程序进行线上报修、投诉、意见反馈时，为了能迅速、及时地解决用</p>户的<p>问题，智慧视光会收集监护人主动提供的时间、地理位置及用户与智慧视光的通信记录等信息。</p>
      <p>4.【信息搜集范围】</p>
      <p>智慧视光应用程序所搜集的几童信息可能包括但不限于儿童姓名、年龄、性别、国籍、身高、体重、血型、医疗保险相关信息、个人及家族病史、诊疗记录及与健康管理</p>及<p>疾病诊疗相关的其他信息等，具体以智慧视光应用程序上列示的相关服务项目要求为准。 </p>
      <p>（二）【儿童信息使用及储存】</p>
      <p>1.【儿童信息使用】</p>
      <p>监护人授权智慧视光有权为向监护人及儿童提供医疗和/或健康保健相关服务之目的而使用、记录及保存儿童信息。 </p>
      <p>2.【信息使用及储存期限】</p>
      <p>除非监护人删除、注销账号或通过系统设置拒绝智慧视光收集或使用儿童信息，智慧视光有权在监护人使用智慧视光应用程序期间持续使用儿童信息。智慧视光将根据相</p>关<p>法律规定对儿童信息进行安全储存。 </p>
      <p>3.【信息储存地域】</p>
      <p>儿童个人信息均存储于中华人民共和国境内的服务器。如因业务需要需向境外提供的，智慧视光将事先取得监护人同意并将严格按照法律规定进行。</p>
      <p>（三）【儿童信息保护】</p>
      <p>1.【依法依约保护】</p>
      <p>智慧视光重视对儿童个人信息的保护，并将根据中国相关法律法规及本条款的儿童个人信息保护条款采取合理的措施对儿童个人信息予以保密及保护。除非获得监护人同</p><p>意，智慧视光不会收集、使用其提供服务所必需以外的儿童信息或者将信息用于提供服务之外的目的。</p>
      <p>2.【保护措施】</p>
      <p>（1）数据加密：智慧视光对于用户的用户名、手机号、注册邮箱、微信号等进行加密存储，保证用户基本信息不会被恶意获取；</p>
      <p>（2）身份鉴别：智慧视光通过校验账号密码或者账号绑定手机验证码，进行用户身份合法性鉴别，防止非经授权的介入；</p>
      <p>（3）访问控制：智慧视光对可能接触到信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们</p>的操<p>作情况等措施；</p>
      <p>（4）网络安全保护：智慧视光应用程序将按照国家网络安全等级保护制度履行相应的安全保护义务，避免网络遭受干扰、破坏或者未经授权的访问，防止儿童个人信息</p>泄露<p>或者被盗窃、篡改，切实安全持续地保存儿童个人信息。一旦智慧视光发现智慧视光应用程序存在安全缺陷、漏洞等风险并且会威胁儿童个人信息安全时，智慧视光</p>会立即<p>采取补救措施并及时告知监护人。 </p>
      <p>（5）对于可能涉及的儿童个人信息，智慧视光进一步采取以下措施予以保障：</p>
      <p>对于收集到的儿童个人信息，智慧视光除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循</p><p>《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后智慧视光会对儿童个人信息进行删除或</p>匿<p>名化处理。</p>
      <p>儿童或其监护人有权随时访问和更正儿童个人信息，还可以向智慧视光提出更正和删除的请求。如监护人对儿童个人信息相关事宜有任何意见、建议或投诉、举报，可以</p>联<p>系智慧视光。智慧视光会随时为用户提供帮助。</p>
      <p>3.【安全事件处理】</p>
      <p>如若发生儿童个人信息安全事件，智慧视光会根据相关法律法规的要求对安全事件进行调查和评估，及时向监护人告知安全事件的基本情况和可能的影响、智慧视光已采</p>取<p>或将要采取的处理措施、监护人可自主防范和降低的风险的建议、对监护人的补救措施等。智慧视光将及时将事件相关情况以智慧视光应用程序通知、短信、电话、邮</p>件或<p>公告等方式通知监护人。 </p>
      <p>（四）【用户对个人信息管理】</p>
      <p>1.【拒绝提供儿童信息】</p>
      <p>若监护人拒绝提供本条款约定的部分或全部儿童信息，可能导致监护人无法通过智慧视光应用程序完成与儿童相关的在线注册、预约、查询检验检查结果、账单查询及/</p>或支<p>付等相关自助服务。 </p>
      <p>2.【监护人对儿童个人信息的管理和控制】</p>
      <p>监护人可随时登录智慧视光应用程序个人账户查看儿童个人信息，如需修改或删除儿童个人信息，监护人可通过拨打智慧视光应用程序上载明的电话进行处理。</p>
      <p>3.【监护人责任】</p>
      <p>监护人在使用智慧视光应用程序平台时应自行判断并谨慎披露儿童个人信息，因监护人不当或不谨慎的行为引起的儿童个人信息保护泄露、财产损失或其他损害由监护人</p>自<p>行承担责任。 </p>
      <p>（五）【允许的信息披露】</p>
      <p>存在以下任一情形的，智慧视光可以披露儿童信息：</p>
      <p>（1）按本条款约定进行披露；</p>
      <p>（2）事先获得监护人的明确授权；</p>
      <p>（3）社会公众通过公开渠道能获取的儿童信息；</p>
      <p>（4）根据法律规定或应相关有权部门要求而做的披露；</p>
      <p>（5）因维护国家及公共安全、公共卫生、重大公共利益，犯罪侦查、起诉、审判和判决执行等需要而披露儿童信息；</p>
      <p>（6）紧急或其他特殊情形下，为保护儿童或其他个人的生命、财产等重大合法权益，智慧视光有权在无法及时获得监护人同意的情兄下对儿童信息进行披露;</p>
      <p>（7）在对儿童信息进行处理无法识别特定儿童个人的前提下，智慧视光有权对智慧视光应用程序儿童信息予以披露、转让或共享；</p>
      <p>（8）其他法律不禁止且智慧视光认为合适的披露。</p>
      <p>三、投诉与举报</p>
      <p>1.监护人发现智慧视光应用程序存在网络信息安全问题或者对儿童个人信息保护条款有任何疑问的，请拔打智慧视光应用程序上载明的电话与智慧视光进行联系。</p>
      <p>2.出于保证服务质量、调查相关事实及促进纠纷解决的目的，智慧视光有权对监护人与智慧视光之间的通话进行录音并有权基于化解纠纷优化技术及服务等目的复制及查</p>听<p>相关录音内容。</p>
      <p>四、其他</p>
      <p>1.本条款主要来源于《智慧视光应用程序用户使用协议》（"用户使用协议"）、《智慧视光应用程序个人信息保护条款》（"个人信息保护条款"），应与《用户使用协</p>议》<p>和《个人信息保护条款》共同阅读并解释，相关名词及定义与《用户使用协议》和《个人信息保护条款》具有相同含义。本条款未尽事宜（包括但不限于适用法律及</p>争议解<p>决等），适用《智慧视光应用程序用户使用协议》的相关规定。</p>
      <p>2.监护人在此明确授权智慧视光为向监护人及儿童提供服务以及持续改进和提升智慧视光服务水平及用户体验之目的，在遵守智慧视光应用程序儿童个人信息保护条款的</p>前<p>提下，对儿童信息进行阅读、收集、保存及使用。监护人有权随时联系智慧视光取消或修改前述授权，智慧视光将根据监护人授权的取消或修改相应停止或更改其服</p>务。
      <p>3.智慧视光谨此再次提醒用户，本条款内容中以加粗方式显著标识的条款，请用户着重阅读。</p>
      <p></p>
      <p></p>
    </div>

    <div v-if="show == 1">
      <h3>用户使用协议</h3>
      <p class="jz">智慧视光应用程序用户使用协议</p>
      <p></p>
      <p>请用户在注册和使用智慧视光应用程序前务必认真仔细阅读并确认充分理解本协议各项条款、规则和要点，特别是注意及重点阅读与用户权利及义务密切相关的条款，包</p>括<p>但不限于限制或免除责任条款、隐私保护政策、法律适用和争议解决条款（包括管辖条款），以及其他以加粗加黑等显示形式的方式标记提示用户注意的重要条款。</p>
      <p>若用户不同意本协议，则用户有充分且完全的权利退出注册和/或使用帐号，用户通过智慧视光应用程序点击注册和/或实际使用帐号的行为即视为用户已阅读、理解并同</p>意<p>接受本协议。如果用户对本协议有任何的疑问、投诉、意见和建议，欢迎用户通过与智慧视光应用程序上载明的方式沟通反馈，智慧视光十分乐意为用户解答。</p>
      <p>一、总则 </p>
      <p>1.【智慧视光应用程序】</p>
      <p>本协议中所称“智慧视光应用程序”指博士视觉官网、微官网、微信公众号、微信小程序等智慧视光在线服务平台的单称或合称。所有用户在使用智慧视光应用程序前，应</p>认<p>真阅读本协议。 </p>
      <p>2. 【智慧视光】</p>
      <p>本协议项下所称"智慧视光"指深圳市博士视觉健康科技有限公司及/或智慧视光应用程序中所列示的相关医院或诊所。 </p>
      <p>3.【协议内容及法律约束力】</p>
      <p>本协议内容包括以下所有正文及智慧视光应用程序已经发布或将来可能发布的各类规则、解释、声明、通告等（除非特有所指，合称为“本协议”）。所有上述规则、解</p>释、<p>声明及通告等都将被视为本协议的组成部分，与协议正文一起构成用户与智慧视光之间具有法律约束力的协议，用户也应当加以遵守。用户浏览、登录、使用或连接</p>智慧视<p>光应用程序的行为将被视为用户已完全了解、接受并同意遵守本用户协议的所有内容。如果用户不同意前述约定，用户应立即联系智慧视光注销用户在智慧视光应</p>用程序上<p>的账户并停止使用智慧视光应用程序。 </p>
      <p>4.【智慧视光修改权】</p>
      <p>智慧视光有权遵照法律法规规定，在必要时修改本协议（包括适时制定并发布其他政策、规则、公告声明）并通过智慧视光应用程序公告通知用户。更新后的协议条款将</p>代<p>替原来的协议并在法律规定的期限届满后正式生效。如用户在协议更新后继续使用智慧视光应用程序，即视为用户已接受修改后的协议。如果用户不接受修改后的协</p>议，则<p>用户有权利停止使用帐号。</p>
      <p>二、用户账号 </p>
      <p>1.【注册及维护】</p>
      <p>用户应按照智慧视光应用程序上的指引及说明完成账户注册。帐号系用于识别用户身份的标识，注册账户完成后，用户对其使用账户的行为负完全责任。用户不得将账户</p>转<p>借、出租、转让等方式许可给第三方使用，否则用户应承担由此可能产生的全部后果及责任。 </p>
      <p>用户有责任自行负责保管帐号的用户名和密码等信息，否则因该等事项引发的法律责任由用户自行承担。凡使用帐号登录的行为，将视为用户本人的操作，操作所产生的</p>电<p>子信息记录均为用户行为的有效凭据。</p>
      <p>2.【账户冒用】</p>
      <p>用户知晓、理解并同意，受限于技术条件，智慧视光无法对非法或未经用户授权使用用户账户及密码的行为做出甄别，因此对非智慧视光原因导致的用户账户和密码泄露</p>及<p>因此所产生的后果，智慧视光不承担责任。若用户发现其密码或者账户遭到未获授权的披露或使用或发生任何其他安全问题时，用户应立即通知智慧视光。 </p>
      <p>3.【账户注销】</p>
      <p>用户有权随时决定注销其在智慧视光应用程序上注册的账户，注销账户可通过拔打智慧视光应用程序上载明的电话进行。</p>
      <p>三、在线自助服务 </p>
      <p>1.【在线自助服务】</p>
      <p>在线自助服务是指用户通过智慧视光应用程序自助完成在线注册、预约、查询检验检查结果、账单查询及支付等自助服务。为此目的，用户同意按智慧视光应用程序的要</p>求<p>提供相应的用户信息并特别授权智慧视光为向用户提供服务之目的而使用、记录及保存该等信息。 </p>
      <p>2.【病历关联】</p>
      <p>用户理解并同意智慧视光可通过用户提供的姓名、身份证号、手机号等个人信息对用户在智慧视光的病历及其他诊疗记录进行关联，以使智慧视光更好地了解用户及向用</p>户<p>提供服务。 </p>
      <p>3.【涉及第三方】</p>
      <p>用户知晓，若用户在智慧视光应用程序中预留了第三方的联系方式，即视为授权该第三方通过在线自助服务查询到用户的相关信息（包括但不限于用户自身以及用户关联</p>的<p>就诊人信息）;对于因此产生的一切后果（包括但不限于用户和/或就诊人信息泄露等），智慧视光不承担任何责任。若用户作为未成年人、限制民事行为能力人或无民</p>事行<p>为能力人（合称"被监护人"）的监护人在系统中预留了用户的手机号码，用户将可以通过智慧视光应用程序为其被监护人预约智慧视光相关服务并查询被监护人相关</p>信息。
      <p>4.【服务变动】</p>
      <p>用户知晓并同意，智慧视光为不断优化用户服务体验，可不时变动在线自助服务内容而无需事先通知用户。</p>
      <p>四、用户行为规范 </p>
      <p>1.【用户行为规范】</p>
      <p>用户在使用智慧视光应用程序过程中应遵守以下行为规范∶</p>
      <p>（1）用户应确保通过智慧视光应用程序和/或其他途径向智慧视光提供的所有用户信息均真实、完整、准确；</p>
      <p>（2）用户在使用智慧视光应用程序与医生或他人交流过程中应互相尊重，平等交流，使用文明用语，避免有辱骂、恐吓他人等行为；</p>
      <p>（3）用户不得为违法、不道德及不合理目的使用智慧视光应用程序；</p>
      <p>（4）用户不得在智慧视光应用程序上传输及发布以下内容（包括但不限于）∶煽动抗拒、破坏法律法规实施的信息及言论；煽动颠覆中国国家政权、破坏中国国家统一的</p>信<p>息及言论；违背中国社会风俗和社会道德的信息及言论；煽动民族仇恨、民族歧视，破坏民族团结的信息及言论；任何侮辱或毁谤他人，性骚扰，或对未成年人有不良</p>影响<p>的信息及言论；淫秽、色情、赌博、暴力、凶杀、恐怖、非法宗教或者教唆犯罪的信息及言论；</p>
      <p>（5）用户不得利用智慧视光应用程序从事窃取商业秘密、窃取其他用户个人信息等违法犯罪活动；</p>
      <p>（6）用户不得传播可能对智慧视光应用程序造成伤害或影响其正常运转的恶意病毒或程序或从事其他妨碍或者破坏智慧视光应用程序（或与智慧视光应用程序连接的服</p>务器<p>及网终）的任何活动；</p>
      <p>（7）用户不得自行或允许他人利用用户账户拷贝、修改智慧视光应用程序软件或软件的任何部分，或对智慧视光应用程序软件或软件的任何部分创作衍生作品，进行反</p>向工<p>程、反编辑或试图从软件或软件的任何部分提取源代码；</p>
      <p>（8）用户不得转售或以其他方式交易用户在智慧视光应用程序获得的相关服务用户不得侵犯第三方权利，特别是他人隐私权等合法权利；</p>
      <p>（9）用户不得利用智慧视光应用程序及其相关服务损害智慧视光及其他第三方合法利益；</p>
      <p>（10）用户应遵守本协议其他约定。 </p>
      <p>2.【不遵守规范后果】</p>
      <p>若用户未遵守以上约定，智慧视光有权采取暂停或者关闭用户账户及其他智慧视光认为合理的措施限制或终止用户继续使用智慧视光应用程序服务。用户须对自己的言论</p>和<p>行为负法律责任，如用户的行为导致智慧视光遭受任何来自第三方的纠纷、诉讼、索赔要求等损害，用户须就此向智慧视光进行赔偿。此外，智慧视光有权在尊重事实</p>及法<p>律的基础上以合理形式支持被侵权方进行维权，包括但不限于向被侵权的第三方及应相关司法及执法部门要求披露与用户侵权有关的个人信息等。</p>
      <p>五、服务中断及终止</p>
      <p>以下情形智慧视光无需事先通知用户即可中断或终止本协议并拒绝用户继续使用智慧视光应用程序而不承担法律责任∶</p>
      <p>（1）用户违反或明确表示将不遵守相关法律法规及本协议的任何规定；</p>
      <p>（2）相关法律规定或有权部门要求智慧视光不得向用户提供本协议所述服务；</p>
      <p>（3）智慧视光基于自身经营计划及商业判断决定停止智慧视光应用程序运营。</p>
      <p>六、免责条款及责任限制</p>
      <p>除本协议约定的其他免责事由外，智慧视光对以下事项同样免责∶</p>
      <p> 1.【不保证】</p>
      <p>考虑到医疗行业的特殊性，对于用户通过智慧视光应用程序向视光医生进行的健康及疾病诊疗方面的咨询，视光医生只限于根据用户的主观描述，利用自身专业领域所积</p>累<p>的知识和经验对用户的问题提供相关信息或初步建议，用户知晓并同意智慧视光医生的在线回答及建议只作为参考，不作为诊断和治疗依据，不能代替医生面诊。 </p>
      <p>2.【用户信息泄露、程序故障等情形】</p>
      <p>鉴于互联网服务的特殊性，用户理解并同意智慧视光无需对以下用户信息的泄露、程序故障等情形遭受损失承担法律责任∶</p>
      <p>（1）用户将账户及密码告知他人，授权他人使用或与他人共享注册账户导致的任何用户信息泄露或由此导致的其他后果；</p>
      <p>（2）因黑客攻击、电脑病毒侵入、电信部门及网络运营公司技术调整或故障、系统维护等非因智慧视光过错导致的用户信息泄露或导致智慧视光应用程序服务中断或延</p>迟<p>等；</p>
      <p>（3）用户违规使用智慧视光的帐号或用户因操作不当造成的损失；</p>
      <p>（4）由于法律法规的变更、司法机关及行政机关等的命令、裁定等原因而导致的智慧视光应用程序中断、终止或延迟；</p>
      <p>（5）智慧视光根据相关法律规定及本协议其他特别约定对外提供用户信息。 </p>
      <p>3.【不可抗力】</p>
      <p>智慧视光对因台风、地震、海啸、洪水、火灾、停电、战争、恐怖袭击等不可抗力因素导致的任何后果不承担法律责任。 </p>
      <p>4.【不稳定因素】</p>
      <p>用户理解并认可，由于本协议下的服务行为涉及到互联网及移动通讯等服务，可能因计算机病毒、各种恶意攻击手段和技术上的限制等各种因素而导致服务中断或不能满</p>足<p>用户要求，因此智慧视光对服务的及时性、安全性、准确性不作任何保证，对因此导致用户不能接收信息、信息传递错误、用户信息泄露及其他用户不良体验等问题不</p>承担<p>责任。 </p>
      <p>5.【系统修复及维修】</p>
      <p>为维修保养、升级智慧视光应用程序或其他合理商业目的，智慧视光有权通过智慧视光应用程序公告通知用户而暂停智慧视光应用程序的任何功能。 </p>
      <p>6.【第三方资源】</p>
      <p>用户理解并同意，智慧视光应用程序提供的相关资讯可能包含第三方网站或资源的超链接，智慧视光不对该等外部网站或资源的可用性、其所载相关内容的真实性、完整</p><p>性、准确性及科学性做出任何认可或担保，用户应自行甄别第三方网站或资源的可靠性并对因信赖或使用第三方网站或资源而产生的后果自行承担责任。用户在接受帐号</p>服<p>务时，有可能会接触到令人不快、不适当或令人厌恶的内容，在任何情况下，智慧视光均不对任何此等内容承担任何责任。无论何种原因，智慧视光不对任何非与智慧</p>视光<p>直接发生的交易或行为承担任何直接、间接、附带或衍生的损失和责任。</p>
      <p>7.【对第三方免责】</p>
      <p>除非各方另有约定或智慧视光另行同意，智慧视光仅对用户本人直接使用智慧视光应用程序及其相关服务按照本协议约定承担相关责任;对于用户以外的其他人使用智慧</p>视光<p>应用程序及其相关服务的行为及用户通过其他人间接使用智慧视光应用程序及其相关服务的行为，智慧视光不承担责任（包括但不限于用户将其从智慧视光应用程序</p>获取的<p>信息及资讯转介第三方或其他用户，导致第三方或其他用户因依赖该转介信息及资讯而产生的任何后果）。 </p>
      <p>8.【责任限制】</p>
      <p>用户确认并同意，智慧视光在任何情况下均不对用户承担任何间接、附带、特殊、后果性或惩罚性的损害赔偿，该等损害赔偿包括但不限于用户的任何利润或可得收益损</p>失<p>（无论是直接还是间接发生）商誉或业务声誉损失、任何数据丢失、替代物品或服务的购买费用、精神损害赔偿或其他无形损失。</p>
      <p>七、用户信息 </p>
      <p>1.【用户信息】</p>
      <p>指所有与用户相关的信息，包括用户和/或就诊人的个人信息（见以下定义）。 </p>
      <p>2.【个人信息】</p>
      <p>指以电子或者其他方式记录的能够单独或者与其他信息结合识别用户和/或就诊人个人身份的各种信息，包括但不限于用户和/或就诊人姓名、头像、护照、身份证号、职</p><p>业、职务、手机电话、银行账号、保险卡号、第三方社交账号、第三方支付账号、电子邮件、个人身高、体重、性别、年龄、血型、个人及家族病史、病情描述、就医需</p><p>求、保险信息等个人生理体征及医疗健康等相关信息。 </p>
      <p>3.【就诊人】</p>
      <p>指用户通过智慧视光应用程序预约或关联的拟在或曾在智慧视光就诊的个人。 </p>
      <p>4.【信息真实】</p>
      <p>对于用户在使用智慧视光应用程序过程中和/或通过其他途径向智慧视光提供的用户信息，用户应本着诚实信用的原则如实提供，因用户提供的信息不真实或不准确导致</p>的后<p>果（包括但不限于误诊、无法联系用户及因此导致的任何损害等）由用户自行承担。 </p>
      <p>5.【违规信息删除】</p>
      <p>用户在智慧视光应用程序上提供及发布的信息应真实并符合本协议项下的用户行为规范及其他约定，否则智慧视光有权自行删除不符合要求的信息而无须承担任何责</p>任。
      <p>6.【用户信息删改】</p>
      <p>除用户注册或发布信息违反用户行为规范或本协议其他约定外，未经用户同意，智慧视光不会对用户信息进行编辑、筛选或篡改。若用户希望删除或更改其在智慧视光应</p>用<p>程序上提供或发布的信息，用户应联系智慧视光对相关信息进行处理，智慧视光应用程序不承担主动删除、销毁用户信息的责任。 </p>
      <p>7.【第三方平台信息】</p>
      <p>用户使用第三方平台账号（包括但不限于微信账号）注册、登录、使用智慧视光应用程序服务的，将被视为用户同意并授权智慧视光应用程序可获取及使用用户在第三方</p>平<p>台填写、登记、公布或记录的全部信息。用户应确保向该等第三方平台提供的全部信息真实、完整、准确。 </p>
      <p>8.【授权使用】</p>
      <p>用户在此明确授权智慧视光为向用户提供服务以及持续改进和提升智慧视光服务水平及用户体验之目的，在遵守本协议个人信息保护条款的前提下，对用户信息进行阅</p>读、<p>收集、保存及使用。用户有权随时联系智慧视光取消或修改前述授权，智慧视光将根据用户授权的取消或修改相应停止或更改其服务。</p>
      <p> 9.【信息推送】</p>
      <p>用户同意智慧视光和/或智慧视光应用程序可通过短信、电话、电子邮件、即时通信的客户端或者其他合法方式，向用户发送包括消息通知、专题推荐、促销活动等信</p>息；如<p>用户拟不再接收此类信息，可通过智慧视光应用程序公布的联系电话或其他方式予以退订。</p>
      <p>八、个人信息保护</p>
      <p>（一）【个人信息搜集】</p>
      <p>1.【信息搜集原则】</p>
      <p>智慧视光应用程序将严格遵循合法、正当、必要的原则收集及使用用户的个人信息。 </p>
      <p>2.【信息搜集目的】</p>
      <p>智慧视光应用程序所搜集的用户个人信息将用于按照用户预约内容为用户提供相应服务及用于持续改进智慧视光服务水平、智慧视光应用程序功能及用户体验。 </p>
      <p>3.【信息搜集方式】</p>
      <p>智慧视光应用程序将通过用户注册、用户主动提供等方式搜集用户信息。 </p>
      <p>4.【信息搜集范围】</p>
      <p>智慧视光应用程序所搜集的信息可能包括但不限于用户姓名、年龄、性别、国籍、身高、体重、血型、医疗保险相关信息、个人及家族病史、诊疗记录及与健康管理及疾</p>病<p>诊疗相关的其他信息等，具体以智慧视光应用程序上列示的相关服务项目要求为准。 </p>
      <p>（二）【个人信息使用及储存】</p>
      <p>1.【用户信息使用】</p>
      <p>用户授权智慧视光有权为向用户提供医疗和/或健康保健相关服务之目的而使用、记录及保存个人信息及用户信息。 </p>
      <p>2.【信息使用及储存期限】</p>
      <p>除非用户删除、注销账号或通过系统设置拒绝智慧视光收集或使用用户信息，智慧视光有权在用户使用智慧视光应用程序期间持续使用用户信息。智慧视光将根据相关法</p>律<p>规定对用户信息进行安全储存。 </p>
      <p>3.【信息储存地域】</p>
      <p>用户的个人信息均存储于中华人民共和国境内的服务器。如因业务需要需向境外提供的，智慧视光将事先取得用户向意并将严格按照法律规定进行。</p>
      <p>（三）【个人信息保护】</p>
      <p>1.【依法依约保护】</p>
      <p>智慧视光重视对用户个人信息的保护，并将根据中国相关法律法规及本协议的个人信息保护条款采取合理的措施对用户个人信息予以保密及保护。除非获得用户同意，智</p>慧<p>视光不会收集、使用其提供服务所必需以外的用户个人信息或者将信息用于提供服务之外的目的。</p>
      <p>2.【保护措施】</p>
      <p>（1）数据加密：智慧视光对于用户的用户名、手机号、注册邮箱、微信号等进行加密存储，保证用户基本信息不会被恶意获取；</p>
      <p>（2）身份鉴别：智慧视光通过校验账号密码或者账号绑定手机验证码，进行用户身份合法性鉴别，防止非经授权的介入；</p>
      <p>（3）访问控制：智慧视光对可能接触到信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们</p>的操<p>作情况等措施</p>
      <p>（4）网络安全保护：智慧视光应用程序将按照国家网络安全等级保护制度履行相应的安全保护义务，避免网络遭受干扰、破坏或者未经授权的访问，防止用户个人信息</p>泄露<p>或者被盗窃、篡改，切实安全持续地保存用户个人信息。一旦智慧视光发现智慧视光应用程序存在安全缺陷、漏洞等风险并且会威胁用户个人信息安全时，智慧视光</p>会立即<p>采取补救措施并及时告知相关用户。 </p>
      <p>3.【安全事件处理】</p>
      <p>如若发生用户个人信息安全事件，智慧视光应用程序会根据相关法律法规的要求，对安全事件进行调查和评估，及时向用户告知安全事件的基本情况和可能的影响、智慧</p>视<p>光已采取或将要采取的处理措施、用户可自主防范和降低的风险的建议、对用户的补救措施等。智慧视光将及时将事件相关情况以智慧视光应用程序通知、短信、电</p>话、邮<p>件或公告等方式通知用户。 </p>
      <p>（四）【用户对个人信息管理】</p>
      <p>1.【拒绝提供用户信息】</p>
      <p>若用户拒绝提供本条款约定的部分或全部用户信息，可能导致用户无法通过智慧视光应用程序完成在线注册、预约、查询检查结果、账单查询及/或支付等相关自助服</p>务。
      <p>2.【用户对个人信息的管理和控制】</p>
      <p>用户可随时登录智慧视光应用程序个人账户查看个人信息，如需修改或删除个人信息，用户可通过拨打智慧视光应用程序上载明的电话进行处理。</p>
      <p>3.【用户责任】</p>
      <p>用户在使用智慧视光应用程序平台时应自行判断并谨慎披露个人信息，因用户不当或不谨慎的行为引起的个人信息保护泄露、财产损失或其他损害由用户自行承担责</p>任。
      <p>（五）【允许的信息披露】</p>
      <p>存在以下任一情形的，智慧视光可以披露用户信息：</p>
      <p>（1）按本条款约定进行披露；</p>
      <p>（2）事先获得用户的明确授权；</p>
      <p>（3）社会公众通过公开渠道能获取的用户信息；</p>
      <p>（4）根据法律规定或应相关有权部门要求而做的披露；</p>
      <p>（5）因维护国家及公共安全、公共卫生、重大公共利益，犯罪侦查、起诉、审判和判决执行等需要而披露用户信息；</p>
      <p>（6）紧急或其他特殊情形下，为保护用户或其他个人的生命、财产等重大合法权益，智慧视光有权在无法及时获得用户同意的情况下对用户信息进行披露；</p>
      <p>（7）在对用户信息进行处理无法识别特定个人用户的前提下，智慧视光有权对智慧视光应用程序用户信息予以披露、转让或共享；</p>
      <p>（8） 其他法律不禁止且智慧视光认为合适的披露。 </p>
      <p>在遵守本协议项下特别约定的个人信息保护与隐私政策外，用户还应认真并完整阅读智慧视光特别针对帐号而制定并适时发布的《智慧视光应用程序个人信息保护条款》</p>，<p>这将更有助于保障用户的个人信息。</p>
      <p>九、儿童个人信息保护</p>
      <p>用户应仔细阅读《智慧视光应用程序儿童个人信息保护政策》，非经未满14周岁未成年人（“儿童”）的监护人同意，用户不得通过智慧视光应用程序上传或提供儿童个人</p>信<p>息。用户作为儿童监护人通过智慧视光应用程序为儿童预约就诊或关联儿童就诊信息的，视为用户同意向智慧视光应用程序提供儿童相关信息。</p>
      <p>十、保密义务</p>
      <p>用户应对在使用智慧视光应用程序及其相关服务过程中所获知的智慧视光未经公开的信息承担保密义务；非经智慧视光同意，用户不得将前述信息向第三方进行披露也不</p>得<p>用于与接受智慧视光服务无关的其他目的或用途。</p>
      <p>十一、广告及促销活动</p>
      <p>用户理解并同意，智慧视光应用程序可能基于商业目的不定期地展示各类优惠活动及/或发布各类广告，用户将不对此提出任何异议或权利主张。</p>
      <p>十二、知识产权</p>
      <p>未经智慧视光明示同意或授权，用户不得以任何方式全部或部分复制转载、引用、链接、抓取或以其他方式使用智慧视光应用程序的logo及信息内容（包括但不限于：源</p>程<p>序和文档、用户界面设计、文案等）。</p>
      <p>十三、适用法律及管辖</p>
      <p> 1.【适用法律】</p>
      <p>本协议的订立、执行、解释、争议解决及其他与本协议有关的事宜均应适用中国人民共和国法律（为本协议之目的不包括香港、澳门及台湾地区的法律）。</p>
      <p> 2.【管辖法院】</p>
      <p>因使用智慧视光应用程序产生的任何争议，先协商解决，协商不成的，任何一方均可将争议提交本协议履行地（即智慧视光应用程序主要运营所在地中国广东省深圳市）</p>有<p>管辖权的人民法院通过诉讼解决。对于因用户在线下接受智慧视光服务所产生的争议，由相关智慧视光服务提供方经营场所所在地有管辖权的人民法院进行管辖。</p>
      <p>十四、投诉与举报</p>
      <p>1.用户发现智慧视光应用程序存在网络信息安全问题、对智慧视光应用程序有任何不满或建议、或者对用户协议有任何疑问的，请拔打智慧视光应用程序上载明的电话与</p>智<p>慧视光进行联系。</p>
      <p>2.出于保证服务质量、调查相关事实及促进纠纷解决的目的，用户同意智慧视光对用户与智慧视光之同的通话进行录音并有权基于化解纠纷、优化技术及服务等目的复制</p>及<p>查听相关录音内容。</p>
      <p>十五、其他 </p>
      <p>1.【不弃权】</p>
      <p>智慧视光未行使或未强制执行任何法定或约定的权利或救济，不视为智慧视光放弃该等权利或救济；智慧视光对特定权利或救济的明确放弃也不视为智慧视光对其他未明</p>确<p>放弃的权利或救济的放弃。</p>
      <p>2.【条款独立性】</p>
      <p>若本协议的任何条款被司法机关认定为无效，则该等无效条款将不再包含在本协议中；但该等无效条款应不对本协议其他条款的效力产生影响，其他条款将继续有效并可</p>强<p>制执行。</p>
      <p>3.【服务终止】</p>
      <p>（1）为了充分利用帐号资源，如果用户存在违反本协议内容情形的，智慧视光有权终止该帐号的使用，停止向用户提供服务。</p>
      <p>（2）用户理解并同意根据智慧视光自身经营政策在任何时候，暂时或永久地终止帐号服务（或任何一部分），用户需及时关注智慧视光应用程序通知，及时备份用户帐</p>号下<p>的资料、信息等，并依据智慧视光公布的措施及时处理用户的资料、信息等。因用户未按照智慧视光公布的措施及时对用户的帐号所涉及的内容、资料、信息等进行</p>处理，<p>智慧视光无需承担任何责任。</p>
      <p>（3）智慧视光谨此再次提醒用户，本条款内容中以加粗方式显著标识的条款，请用户着重阅读。用户点击“立即注册”按钮即视为用户完全接受本条款，在点击之前请用</p>户再<p>次确认已知悉并完全理解本条款的全部内容。</p>
      <p></p>
      <p></p>
      <p></p>
    </div>

    <div v-if="show == 2">
      <h3>个人信息保护条款</h3>
      <p class="jz">智慧视光应用程序</p>
      <p>个人信息保护条款</p>
      <p>一、总则 </p>
      <p>（一）特别提示</p>
      <p>请用户仔细阅读智慧视光的《个人信息保护条款》（尤其是加粗划线的内容）并确定了解智慧视光对用户个人信息的处理规则。阅读过程中，如用户有任何疑问，可及时</p>与<p>智慧视光联系（联系方式以智慧视光应用程序上载明的联系方式为准）。如用户不同意协议中的任何条款，应立即停止使用智慧视光应用程序。用户使用智慧视光提供</p>的服<p>务，即意味着用户已阅读所有条款，并对本条款的含义及相应的法律后果已全部通晓并充分理解，并且同意智慧视光按照本条款收集、使用并保护用户的个人信息。</p>
      <p>（二）定义</p>
      <p>1.【智慧视光应用程序】</p>
      <p>本条款中所称"智慧视光应用程序"指博士视觉官网、微官网、微信小程序的单称或合称。所有用户在使用智慧视光应用程序前，应认真阅读本条款 </p>
      <p>2.【智慧视光】</p>
      <p>本条款项下所称"智慧视光"指深圳市博士视觉健康科技有限公司/或智慧视光应用程序中所列示的相关医院或诊所。 </p>
      <p>3.【用户信息】</p>
      <p>指所有与用户相关的信息，包括用户和/或就诊人的个人信息（见以下定义）。 </p>
      <p>4.【个人信息】</p>
      <p>指以电子或者其他方式记录的能够单独或者与其他信息结合识别用户和/或就诊人个人身份的各种信息，包括但不限于用户和/或就诊人姓名、头像、护照、身份证号、职</p><p>业、职务、手机电话、银行账号、保险卡号、第三方社交账号、第三方支付账号、电子邮件、个人身高、体重、性别、年龄、血型、个人及家族病史、病情描述、就医需</p><p>求、保险信息等个人生理体征及医疗健康等相关信息。</p>
      <p> 5.【智慧视光修改权】</p>
      <p>智慧视光有权修改本条款并通过智慧视光应用程序公告通知用户。如用户在本条款更新后拟继续使用智慧视光应用程序，应了解、接受并遵守更新后的个人信息保护条</p>款。
      <p>（三）适用范围</p>
      <p>本条款适用于智慧视光通过运营的智慧视光应用程序为用户提供的服务。需要特别说明的是，本条款不适用于智慧视光应用程序平台上显示但由其他接入平台第三方向用</p>户<p>提供的服务。</p>
      <p>二、个人信息保护条款 </p>
      <p>（一）【个人信息搜集】</p>
      <p>1.【信息搜集原则】</p>
      <p>智慧视光应用程序将严格遵循合法、正当、必要的原则收集及使用用户的个人信息。 </p>
      <p>2.【信息搜集目的】</p>
      <p>智慧视光应用程序所搜集的用户个人信息将用于按照用户预约内容为用户提供相应服务及用于持续改进智慧视光服务水平、智慧视光应用程序功能及用户体验。 </p>
      <p>3.【信息搜集方式】</p>
      <p>智慧视光应用程序将通过自动收集方式、用户注册主动提供、授权第三方提供方式搜集用户信息。 </p>
      <p>（1）自动收集方式</p>
      <p>当用户使用智慧视光应用程序时，出于运营、管理、安全保障、改进技术、优化服务等的需要，智慧视光会自动收集用户使用期间的登录信息、设备信息、浏览信息。此</p>等<p>信息包括但不限于：用户使用智慧视光应用程序平台的IP地址、访问日期和次数、硬件和软件信息、设备信息（如设备地址）、设备事件信息等。</p>
      <p>（2）主动提供方式</p>
      <p>当用户使用智慧视光应用程序时，对于用户为了使用智慧视光应用程序提供的相关功能和/或服务而主动提供的信息，智慧视光会予以收集。具体如下：</p>
      <p>当用户使用智慧视光应用程序进行线上预约就诊时，为了用户能够顺利地完成预约就诊，智慧视光会收集用户主动提供的姓名、头像、护照、身份证号、职业、职务、手</p>机<p>电话、银行账号、保险卡号、第三方社交账号、第三方支付账号、电子邮件、个人身高、体重、性别、年龄、血型、个人及家族病史、病情描述、就医需求、保险信息</p>等个<p>人生理体征及医疗健康等相关信息；</p>
      <p>当用户使用智慧视光应用程序进行线上报修、投诉、意见反馈时，为了能迅速、及时地解决用户的问题，智慧视光会收集用户主动提供的时间、地理位置及用户与智慧视</p>光<p>的通信记录等信息。</p>
      <p>（3）授权第三方提供方式</p>
      <p>当用户使用智慧视光应用程序时，对于用户为了使用智慧视光应用程序提供的相关功能和/或服务而授权第三方提供的信息，智慧视光会予以收集。具体如下：</p>
      <p>当用户使用智慧视光应用程序时，为了用户能顺利完成注册、登录等服务，智慧视光会收集用户通过授权第三方（如微信）向智慧视光提供的用户的账户信息（头像、昵</p>称<p>等）；</p>
      <p>请用户注意，用户在使用智慧视光的产品和/或服务时的登录信息、设备信息、浏览信息，主动提供的信息，授权第三方提供的信息，如无法单独识别用户的身份，则不</p>属于<p>用户的个人信息，应不适用于本条款，智慧视光有权以任何的目的对其进行使用；只有当用户主动提供的信息、授权第三方提供的信息分别或与智慧视光自动收集的</p>登录、<p>设备、浏览信息结合使用并可以识别用户的身份时，则在分别和结合使用期间，智慧视光会将其作为用户的个人信息，并按照本条款对其进行处理与保护。</p>
      <p>4.【信息搜集范围】</p>
      <p>智慧视光应用程序所搜集的信息可能包括但不限于用户姓名、年龄、性别、国籍、身高、体重、血型、医疗保险相关信息、个人及家族病史、诊疗记录及与健康管理及疾</p>病<p>诊疗相关的其他信息等，具体以智慧视光应用程序上列示的相关服务项目要求为准。 </p>
      <p>（二）【个人信息使用及储存】</p>
      <p>1.【用户信息使用】</p>
      <p>用户授权智慧视光有权为向用户提供医疗和/或健康保健相关服务之目的而使用、记录及保存个人信息及用户信息。 </p>
      <p>2.【信息使用及储存期限】</p>
      <p>除非用户删除、注销账号或通过系统设置拒绝智慧视光收集或使用用户信息，智慧视光有权在用户使用智慧视光应用程序期间持续使用用户信息。智慧视光将根据相关法</p>律<p>规定对用户信息进行安全储存。 </p>
      <p>3.【信息储存地域】</p>
      <p>用户的个人信息均存储于中华人民共和国境内的服务器。如因业务需要需向境外提供的，智慧视光将事先取得用户向意并将严格按照法律规定进行。</p>
      <p>（三）【个人信息保护】</p>
      <p>1.【依法依约保护】</p>
      <p>智慧视光重视对用户个人信息的保护，并将根据中国相关法律法规及本条款的个人信息保护条款采取合理的措施对用户个人信息予以保密及保护。除非获得用户同意，智</p>慧<p>视光不会收集、使用其提供服务所必需以外的用户个人信息或者将信息用于提供服务之外的目的。</p>
      <p>2.【保护措施】</p>
      <p>（1）数据加密：智慧视光对于用户的用户名、手机号、注册邮箱、微信号等进行加密存储，保证用户基本信息不会被恶意获取；</p>
      <p>（2）身份鉴别：智慧视光通过校验账号密码或者账号绑定手机验证码，进行用户身份合法性鉴别，防止非经授权的介入；</p>
      <p>（3）访问控制：智慧视光对可能接触到信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们</p>的操<p>作情况等措施</p>
      <p>（4）网络安全保护：智慧视光应用程序将按照国家网络安全等级保护制度履行相应的安全保护义务，避免网络遭受干扰、破坏或者未经授权的访问，防止用户个人信息</p>泄露<p>或者被盗窃、篡改，切实安全持续地保存用户个人信息。一旦智慧视光发现智慧视光应用程序存在安全缺陷、漏洞等风险并且会威胁用户个人信息安全时，智慧视光</p>会立即<p>采取补救措施并及时告知相关用户。 </p>
      <p>3.【安全事件处理】</p>
      <p>如若发生用户个人信息安全事件，智慧视光应用程序会根据相关法律法规的要求，对安全事件进行调查和评估，及时向用户告知安全事件的基本情况和可能的影响、智慧</p>视<p>光已采取或将要采取的处理措施、用户可自主防范和降低的风险的建议、对用户的补救措施等。智慧视光将及时将事件相关情况以智慧视光应用程序通知、短信、电</p>话、邮<p>件或公告等方式通知用户。 </p>
      <p>（四）【用户对个人信息管理】</p>
      <p>1.【拒绝提供用户信息】</p>
      <p>若用户拒绝提供本条款约定的部分或全部用户信息，可能导致用户无法通过智慧视光应用程序完成在线注册、预约、查询检查结果、账单查询及/或支付等相关自助服</p>务。
      <p>2.【用户对个人信息的管理和控制】</p>
      <p>用户可随时登录智慧视光应用程序个人账户查看个人信息，如需修改或删除个人信息，用户可通过拨打智慧视光应用程序上载明的电话进行处理。</p>
      <p>3.【用户责任】</p>
      <p>用户在使用智慧视光应用程序平台时应自行判断并谨慎披露个人信息，因用户不当或不谨慎的行为引起的个人信息保护泄露、财产损失或其他损害由用户自行承担责</p>任。
      <p>（五）【允许的信息披露】</p>
      <p>存在以下任一情形的，智慧视光可以披露用户信息：</p>
      <p>（1）按本条款约定进行披露；</p>
      <p>（2）事先获得用户的明确授权；</p>
      <p>（3）社会公众通过公开渠道能获取的用户信息；</p>
      <p>（4）根据法律规定或应相关有权部门要求而做的披露；</p>
      <p>（5）因维护国家及公共安全、公共卫生、重大公共利益，犯罪侦查、起诉、审判和判决执行等需要而披露用户信息；</p>
      <p>（6）紧急或其他特殊情形下，为保护用户或其他个人的生命、财产等重大合法权益，智慧视光有权在无法及时获得用户同意的情况下对用户信息进行披露；</p>
      <p>（7）在对用户信息进行处理无法识别特定个人用户的前提下，智慧视光有权对智慧视光应用程序用户信息予以披露、转让或共享；</p>
      <p>（8） 其他法律不禁止且智慧视光认为合适的披露。</p>
      <p>三、儿童个人信息保护</p>
      <p>用户应仔细阅读《智慧视光应用程序儿童个人信息保护政策》，非经未满14周岁未成年人（"儿童"）的监护人同意，用户不得通过智慧视光应用程序上传或提供儿童个人</p>信<p>息。用户作为儿童监护人通过智慧视光应用程序为儿童预约就诊或关联儿童就诊信息的，视为用户同意向智慧视光应用程序提供儿童相关信息。</p>
      <p>四、投诉与举报</p>
      <p>1.用户发现智慧视光应用程序存在网络信息安全问题或者对个人信息保护条款有任何疑问的，请拨打智慧视光应用程序上载明的电话与智慧视光进行联系。</p>
      <p>2.出于保证服务质量、调查相关事实及促进纠纷解决的目的有权对用户与智慧视光之间的通话进行录音并有权基于化解纠纷、优化技术及服务等目的复制及查听相关录音</p>内<p>容。</p>
      <p>五、其他</p>
      <p>本条款主要来源于《智慧视光应用程序用户使用协议》（"用户使用协议"），应与《用户使用协议》共同阅读并解释，相关名词及定义与《用户使用协议》具有相同含</p>义。<p>本条款未尽事宜（包括但不限于适用法律及争议解决等），适用《智慧视光应用程序用户使用协议》的相关规定。</p>
      <p>注意∶</p>
      <p>1.用户在此明确授权智慧视光为向用户提供服务以及持续改进和提升智慧视光服务水平及用户体验之目的，在遵守智慧视光应用程序个人信息保护条款的前提下，对用户</p>信<p>息进行阅读、收集、保存及使用。用户有权随时联系智慧视光取消或修改前述授权，智慧视光将根据用户授权的取消或修改相应停止或更改其服务。</p>
      <p>2.用户同意智慧视光和/或智慧视光应用程序可通过短信、电话、电子邮件、即时通信的客户端或者其他合法方式，向用户发送包括消息通知、专题推荐、促销活动等信</p>息;<p>如用户拟不再接收此类信息，可通过智慧视光应用程序公布的联系电话或其他方式予以退订。</p>
      <p>3.智慧视光谨此再次提醒用户，本条款内容中以加粗方式显著标识的条款，请用户着重阅读。用户点击“立即注册”按钮即视为用户完全接受本条款，在点击之前请用户再</p>次<p>确认已知悉并完全理解本条款的全部内容。</p>
      <p></p>
      <p></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "xy",
  data() {
    return {
      show: 0
    }
  },
  created() {
    this.show = this.$route.query.idx
  }
}
</script>

<style scoped>
  .xy {
    width: 94vw;
    padding: 0 3vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    text-align: left;
  }
  p {
    padding-bottom: .05rem;
  }
  h3 {text-align: center;}
  .jz {
    text-align: center;
    padding-bottom: .1rem;
  }
</style>